import Alpine from "alpinejs";
import Player from "@vimeo/player";

const defaultOptions: Player.Options = {
    loop: true,
    autoplay: true,
    background: true,
    controls: false,
};

interface VimeoPlyerAlpine {
    portraitSrc: string;
    landscapeSrc: string;
    paused: boolean;
    init: () => void;
    cleanup: Function | null;
}

window.addEventListener("alpine:init", () => {
    Alpine.data(
        "vimeoPlayer",
        ({
            portraitSrc,
            landscapeSrc,
            options = defaultOptions,
        }): VimeoPlyerAlpine => ({
            portraitSrc,
            landscapeSrc,
            paused: true,
            cleanup: null,
            init() {
                const player = new Player(this.$refs.container, {
                    width: this.$el.clientWidth,
                    url:
                        this.$store.viewport.isPortrait &&
                        this.$store.viewport.isMobile
                            ? portraitSrc
                            : landscapeSrc,
                    ...options,
                    muted: options.autoplay,
                });

                player.on("loaded", async () => {
                    this.ratio = await getVideoRatio();

                    setIframeSize();
                });

                player.on("play", () => {
                    this.paused = false;
                });

                player.on("pause", () => {
                    this.paused = true;
                });

                player.on("ended", async () => {
                    await player.setCurrentTime(0);
                    await player.pause();
                });

                this.play = async () => {
                    await player.play();
                    await player.setVolume(1);
                };
                this.pause = async () => {
                    await player.pause();
                };

                this.toggle = async () => {
                    if (this.paused) {
                        await this.play();
                    } else {
                        await this.pause();
                    }
                };

                this.cleanup = () => {
                    console.log("swipe swipe swipe");
                    player.destroy();
                    window.removeEventListener("resize", setIframeSize);
                };

                const getVideoRatio = async () => {
                    return (
                        (await player.getVideoWidth()) /
                        (await player.getVideoHeight())
                    );
                };

                const setIframeSize = () => {
                    const iframe = player.element;

                    if (!iframe) {
                        return;
                    }

                    // set iframe width and height based on the ratio of this.$el so it always fills the container
                    const elWidth = this.$el.clientWidth;
                    const elHeight = this.$el.clientHeight;
                    const elRatio = elWidth / elHeight;

                    if (elRatio > this.ratio) {
                        iframe.width = elWidth.toString();
                        iframe.height = (elWidth / this.ratio).toString();
                    } else {
                        iframe.width = (elHeight * this.ratio).toString();
                        iframe.height = elHeight.toString();
                    }
                };

                Alpine.effect(async () => {
                    await player.loadVideo(
                        this.$store.viewport.isPortrait &&
                            this.$store.viewport.isMobile
                            ? portraitSrc
                            : landscapeSrc
                    );
                });

                window.addEventListener(
                    "resize",
                    window.debounce(setIframeSize, 200)
                );
            },
        })
    );
});
