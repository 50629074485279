import Alpine from "alpinejs";

function getDeviceOrientation() {
    return window.matchMedia("(orientation: portrait)").matches
        ? "portrait"
        : "landscape";
}

Alpine.store("viewport", {
    orientation: getDeviceOrientation(),
    width: window.innerWidth,
    height: window.innerHeight,
    get isPortrait() {
        return this.orientation.includes("portrait");
    },
    get isLandscape() {
        return this.orientation.includes("landscape");
    },
    get isMobile() {
        return this.width < 768;
    },
    get isTablet() {
        return this.width >= 768 && this.width < 1024;
    },
    get isDesktop() {
        return this.width >= 1024;
    },
    get isSmall() {
        return this.width < 1024;
    },
});

window.addEventListener("resize", () => {
    Alpine.store("viewport").width = window.innerWidth;
    Alpine.store("viewport").height = window.innerHeight;
});

window.matchMedia("(orientation: portrait)").addEventListener("change", (e) => {
    Alpine.store("viewport").orientation = e.matches ? "portrait" : "landscape";
});
