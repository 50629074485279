import collapse from "@alpinejs/collapse";
import focus from "@alpinejs/focus";
import intersect from "@alpinejs/intersect";
import morph from "@alpinejs/morph";
import persist from "@alpinejs/persist";

import * as Turbo from "@hotwired/turbo";
import Alpine from "alpinejs";
import "focus-visible";
import debounce from "lodash.debounce";
import "./components/LottiePlayer";
import "./components/Slider";
import "./components/VideoPlayer";
import "./components/VimeoPlayer";
import "./directives/destroy";
import "./directives/responsiveImageDirective";
import "./stores/searchParams";
import "./stores/viewport";

// Global get CSRF token function (used by forms).
window.getToken = async () => {
    return await fetch("/!/statamic-peak-tools/dynamic-token/refresh")
        .then((res) => res.json())
        .then((data) => {
            return data.csrf_token;
        })
        .catch((error) => {
            this.error = "Something went wrong. Please try again later.";
        });
};

// Call Alpine.
window.Alpine = Alpine;
window.debounce = debounce;
Alpine.plugin([collapse, focus, morph, persist, intersect]);
Alpine.start();

Turbo.start();
