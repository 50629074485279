// import Swiper JS
import Alpine from "alpinejs";
import Swiper, { Autoplay, Keyboard, Navigation, Pagination } from "swiper";
// import Swiper styles
import "swiper/css/bundle";

const autoplayDefaultOptions = {
    delay: 5000,
    pauseOnMouseEnter: true,
    disableOnInteraction: false,
};

document.addEventListener("alpine:init", () => {
    Alpine.data("slider", () => ({
        swiper: undefined,
        activeIndex: 0,
        init() {
            this.swiper = new Swiper(this.$refs.container, {
                modules: [Navigation, Keyboard, Autoplay, Pagination],
                rewind: true,
                slidesPerView: 1.3,
                spaceBetween: 20,
                keyboard: {
                    enabled: true,
                    onlyInViewport: true,
                    pageUpDown: false,
                },
                navigation: {
                    enabled: true,
                    nextEl: this.$refs.nextEl,
                    prevEl: this.$refs.prevEl,
                },
                pagination: {
                    el: this.$refs.pagination,
                    clickable: false,
                    type: "bullets",
                    bulletActiveClass: "bg-yellow",
                    bulletClass: "flex-1 h-1 bg-grey block",
                    renderBullet: (index, className) => {
                        return `<div class="${className}"></div>`;
                    },
                },
            });

            this.swiper.on("slideChange", () => {
                this.activeIndex = this.swiper.activeIndex;
            });
        },
    }));
});
