import Alpine from "alpinejs";

const setSources = (imgEl) => {
    const imgWidth = imgEl.getBoundingClientRect().width;

    const sizes = Math.ceil((imgWidth / window.innerWidth) * 100) + "vw";

    imgEl.parentNode.querySelectorAll("source").forEach((source) => {
        source.sizes = sizes;
    });
};

Alpine.directive("responsive-image", (el, {}, { cleanup }) => {
    let observer = new ResizeObserver((entries) => {
        entries.forEach((entry) => {
            setSources(entry.target);
        });
    });

    observer.observe(el);

    // el.addEventListener("load", () => {
    //     setSources(el);
    // });

    cleanup(() => {
        observer.disconnect();
    });
});
