import Alpine from "alpinejs";

document.addEventListener("alpine:init", () => {
    Alpine.directive(
        "destroy",
        (el, { expression }, { evaluateLater, cleanup }) => {
            const clean = evaluateLater(expression);
            cleanup(() => clean());
        }
    );
});
