import Alpine from "alpinejs";
import lottie from "lottie-web";
import type { AnimationConfig, LottiePlayer } from "lottie-web";
import { create } from "@lottiefiles/lottie-interactivity";

const defaultOptions: AnimationConfig = {
    loop: true,
    autoplay: true,
    renderer: "svg",
};

interface LottiePlayerAlpine {
    lottiePlayer: LottiePlayer | null;
    init: () => void;
    cleanup: () => Function | void;
}

window.addEventListener("alpine:init", () => {
    Alpine.data(
        "lottiePlayer",
        ({
            path,
            options = defaultOptions,
            interactivity,
            playOnHover,
        }): LottiePlayerAlpine => ({
            lottiePlayer: null,
            cleanup() {
                if (this.lottiePlayer) {
                    this.lottiePlayer.destroy();
                    this.lottiePlayer = null;
                }
            },
            init() {
                const lottiePlayer: LottiePlayer = lottie.loadAnimation({
                    ...options,
                    path,
                    container: this.$el,
                });

                this.lottiePlayer = lottiePlayer;

                if (interactivity) {
                    const { actions, mode } = interactivity;

                    create({
                        mode,
                        player: this.lottiePlayer,
                        actions,
                    });
                }

                if (playOnHover) {
                    this.$watch("$data.hover", (value) => {
                        if (value) {
                            lottiePlayer.play();
                        } else {
                            lottiePlayer.stop();
                        }
                    });
                }

                window.addEventListener("turbo:before-cache", this.cleanup);
            },
        })
    );
});
