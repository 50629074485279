import Alpine from "alpinejs";

Alpine.store("searchParams", {
    searchParams: new URLSearchParams(window.location.search),
    has(key: string) {
        return this.searchParams.has(key);
    },
    get(key: string) {
        return this.searchParams.get(key);
    },
    set(key: string, value: string) {
        this.searchParams.set(key, value);
        this.update();
    },
    delete(key: string) {
        this.searchParams.delete(key);
        this.update();
    },
    toggle(key: string, value: string) {
        // If the key exists and is the same as the value, remove it.
        if (this.get(key) === value) {
            this.delete(key);
        } else {
            this.set(key, value);
        }
    },
    update() {
        const params = this.searchParams.toString();

        const url = `${window.location.pathname}${params ? `?${params}` : ""}`;

        window.history.pushState({}, "", url);

        this.searchParams = new URLSearchParams(window.location.search);
    },
});
